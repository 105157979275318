import React from "react";
import format from 'date-fns/format';

const ReportComponent = ({ title,reports }) => {

const getBostonSummary = () => {
  let a2Dsummarystats = {total_encounters:0,active:0, red:0, yellow:0, green:0, resolved:0, transport:0}
  Object?.keys(reports?.races ?? []).forEach((race,index)=>{
      if(race !== "BM23 Division E - Finish Area"){
        a2Dsummarystats.total_encounters += reports?.races?.[race]?.total_encounters;
        a2Dsummarystats.active += reports?.races?.[race]?.active;
        a2Dsummarystats.red += reports?.races?.[race]?.red.count;
        a2Dsummarystats.yellow += reports?.races?.[race]?.yellow.count;
        a2Dsummarystats.green += reports?.races?.[race]?.green.count;
        a2Dsummarystats.resolved += reports?.races?.[race]?.resolved;
        a2Dsummarystats.transport += reports?.races?.[race]?.transport;
      }
  })
  return a2Dsummarystats;
}

const bostonSummaryStatus = getBostonSummary();
const countStyle = { "fontSize": 15 };
  return (
    <div className="container">
        <h4><strong className='summary-label'>{title}</strong></h4>
        <br/>
        <p><strong className='summary-label'>Report as of: {reports.time_initiated  ? format(new Date(reports.time_initiated.replace(" ","T")), "LLL d, yyyy 'at' hh:mm a"): ""} </strong>  </p>
        <p><strong className='summary-label'>Until {reports.end_time ? format(new Date(reports.end_time?.replace(" ","T")), "LLL d, yyyy 'at' hh:mm a"): ""} </strong>  </p>
        <br/>
        <br/>
        <p><strong className='summary-label'>Total Transports: {reports.total_transports} </strong></p>
        <table className="table hospital_table">
          <thead>
            <tr>
              <th scope="col">Hospital</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(Object.fromEntries(Object.entries(reports.hospital_statistics).sort((a, b) => b[1] - a[1]))).map((hospital,index) => (
              <tr key={index}>
                <td>{hospital?.replace('&#039;',"'")}</td>
                <td>{reports.hospital_statistics[hospital]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br/>
        <hr/>
        <br/>
        
        <div className="new-page" style={{marginBottom: 50}} >
          <p><strong className='summary-label'>Total Unique Persons Seen: {reports?.total_patients ?? 'N/A'} | Total Medical Incidents: {reports?.total_encounters ?? 'N/A'} </strong></p>
          <table className={`table master_races_table`}>
            <thead>
              <tr>{title}</tr>
              <tr>
                <th scope="col" style={countStyle}> Medical Area</th>
                <th scope="col" style={countStyle}>Total</th>
                <th scope="col" style={countStyle}>Active</th>
                <th scope="col" style={countStyle}>{reports?.red?.name}</th>
                <th scope="col" style={countStyle}>{reports?.yellow?.name}</th>
                <th scope="col" style={countStyle}>{reports?.green?.name}</th>
                <th scope="col" style={countStyle}>Minor</th>
                <th scope="col" style={countStyle}>Resolved</th>
                <th scope="col" style={countStyle}>Transport</th>
              </tr>
              <tr>
                <th scope="col" style={countStyle}> Total</th>
                <th scope="col" style={countStyle}>{reports?.total_encounters}</th>
                <th scope="col" style={countStyle}>{reports?.active}</th>
                <th scope="col" style={countStyle}>{reports?.red?.count}</th>
                <th scope="col" style={countStyle}>{reports?.yellow?.count}</th>
                <th scope="col" style={countStyle}>{reports?.green?.count}</th>
                <th scope="col" style={countStyle}>{reports?.minor_assistance_count ?? 0}</th>
                <th scope="col" style={countStyle}>{reports?.resolved}</th>
                <th scope="col" style={countStyle}>{reports?.transport}</th>
              </tr>
            </thead>
            <tbody>
              {
                title === '2024 Boston Marathon RaceSafe' ? 
                (<>
                <tr>
                  <td>Course Medical Station Totals</td>
                  <td>{bostonSummaryStatus?.total_encounters}</td>
                  <td>{bostonSummaryStatus?.active}</td>
                  <td>{bostonSummaryStatus?.red}</td>
                  <td>{bostonSummaryStatus?.yellow}</td>
                  <td>{bostonSummaryStatus?.green}</td>
                  <td>{bostonSummaryStatus?.minor_assistance_count ?? 0}</td>
                  <td>{bostonSummaryStatus?.resolved}</td>
                  <td>{bostonSummaryStatus?.transport}</td>
                </tr>
                <tr>
                <td>Finish Area Station Totals</td>
                  <td>{reports?.races?.['BM24 Division E - Finish Area']?.total_encounters}</td>
                  <td>{reports?.races?.['BM24 Division E - Finish Area']?.active}</td>
                  <td>{reports?.races?.['BM24 Division E - Finish Area']?.red.count}</td>
                  <td>{reports?.races?.['BM24 Division E - Finish Area']?.yellow.count}</td>
                  <td>{reports?.races?.['BM24 Division E - Finish Area']?.green.count}</td>
                  <td>{reports?.races?.['BM24 Division E - Finish Area']?.minor_assistance_count ?? 0}</td>
                  <td>{reports?.races?.['BM24 Division E - Finish Area']?.resolved}</td>
                  <td>{reports?.races?.['BM24 Division E - Finish Area']?.transport}</td>
                </tr>
                </>) :
                (Object?.keys(reports?.races ?? []).map((race,index) => (
                  <>
                  <tr key={index}>
                    <td> <span style={{fontWeight: 'bold'}}>{race}</span></td>
                    <td>{reports?.races?.[race]?.total_encounters}</td>
                    <td>{reports?.races?.[race]?.active}</td>
                    <td>{reports?.races?.[race]?.red.count}</td>
                    <td>{reports?.races?.[race]?.yellow.count}</td>
                    <td>{reports?.races?.[race]?.green.count}</td>
                    <td>{reports?.races?.[race]?.minor_assistance_count ?? 0}</td>
                    <td >
                      {reports?.races?.[race]?.resolved}
                    </td>
                    <td >
                      {reports?.races?.[race]?.transport}
                    </td>
                  </tr>
                  </>
                )))
              }
            </tbody>
          </table>
        </div>

        <br/>
        <hr/>
        <br/>
        {Object?.keys(reports?.races ?? []).map((race,index) => (
            <div className="new-page" style={{marginBottom: 50}} key={index}>
              <p><strong className='summary-label'>Total Unique Persons Seen: {reports?.races?.[race]?.total_patients ?? 'N/A'} | Total Medical Incidents: {reports?.races?.[race]?.total_encounters ?? 'N/A'} </strong></p>
              <table className={`table station_table_${index}`}>
                <thead>
                  <tr>{race}</tr>
                  <tr>
                    <th scope="col" style={countStyle}> Medical Area</th>
                    <th scope="col" style={countStyle}>Total</th>
                    <th scope="col" style={countStyle}>Active</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.red?.name}</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.yellow?.name}</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.green?.name}</th>
                    <th scope="col" style={countStyle}>Minor</th>
                    <th scope="col" style={countStyle}>Resolved</th>
                    <th scope="col" style={countStyle}>Transport</th>
                  </tr>
                  <tr>
                    <th scope="col" style={countStyle}> Total</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.race_total}</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.active}</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.red?.count}</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.yellow?.count}</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.green?.count}</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.minor_assistance_count ?? 0}</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.resolved}</th>
                    <th scope="col" style={countStyle}>{reports?.races?.[race]?.transport}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object?.keys(reports?.races?.[race]?.statistics ?? []).map((station,index) => (
                    <>
                    <tr key={index}>
                      <td> <span style={{fontWeight: 'bold'}}>{station}</span></td>
                      <td>{reports?.races?.[race].statistics?.[station].total_encounters}</td>
                      <td>{reports?.races?.[race].statistics?.[station].active}</td>
                      <td>{reports?.races?.[race].statistics?.[station].red.count}</td>
                      <td>{reports?.races?.[race].statistics?.[station].yellow.count}</td>
                      <td>{reports?.races?.[race].statistics?.[station].green.count}</td>
                      <td >
                        {reports?.races?.[race].statistics?.[station]?.minor_assistance_count ?? 0}
                      </td>
                      <td >
                        {reports?.races?.[race].statistics?.[station].resolved}
                      </td>
                      <td >
                        {reports?.races?.[race].statistics?.[station].transport}
                      </td>
                    </tr>
                    {Object?.keys(reports?.races?.[race].statistics?.[station]?.sub ?? []).map((subStation, index) => (
                      <tr key={index}>
                        <td><span style={{marginLeft:7}}>-{subStation}</span></td>
                      <td>{reports?.races?.[race].statistics?.[station]?.sub?.[subStation]?.active + reports?.races?.[race].statistics?.[station]?.sub?.[subStation].resolved}</td>
                      <td>{reports?.races?.[race].statistics?.[station]?.sub?.[subStation]?.active}</td>
                      <td>{reports?.races?.[race].statistics?.[station]?.sub?.[subStation]?.red?.count}</td>
                      <td>{reports?.races?.[race].statistics?.[station]?.sub?.[subStation]?.yellow?.count}</td>
                      <td>{reports?.races?.[race].statistics?.[station]?.sub?.[subStation]?.green?.count}</td>
                      <td >
                        {reports?.races?.[race].statistics?.[station]?.sub?.[subStation]?.minor_assistance_count ?? 0}
                      </td>
                      <td >
                        {reports?.races?.[race].statistics?.[station]?.sub?.[subStation]?.resolved}
                      </td>
                      <td >
                        {reports?.races?.[race].statistics?.[station]?.sub?.[subStation]?.transport}
                      </td>
                      </tr>
                    ))}
                    </>
                    

                  ))}
                </tbody>
              </table>
            </div>
        ))}
        
    </div>
  );
};

export default ReportComponent;