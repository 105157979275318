import ReportPage from "./app/command-center/components/triageReportPage/reportPage";

function App() {
  return (
    <div className="App">
      <ReportPage/>
    </div>
  );
}

export default App;
