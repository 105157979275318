import React, { useState, useEffect } from "react";
import ReportComponent from "./reportComponent";
import { Form, FormGroup } from "react-bootstrap"
import axios from 'axios';
import BounceLoader from "react-spinners/ClipLoader";

const ReportPage = () => {
  const [reportTitle, setReportTitle] = useState('Quick Report')
  const [title, setTitle] = useState('');
  const [stations, setStations] = useState({});
  const [reports, setReports] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get(`${window.location.origin}/triage-data-report-api/${window.location.pathname.replace('/index.php', '').replace('/command-center/', '').split('/triage-data-report')[0]}${window.location.search}`)
        .then((response) => {
            setTitle(response.data.raceTitle);
            setStations(JSON.parse(response.data.stations));
            setReports(JSON.parse(response.data.reports));
            setReportTitle(JSON.parse(response.data.reports)?.quick_report_title ?? 'Quick Report');
            setIsLoading(false);
        })
        .catch(error => console.error('axios', error));
  }, []);

  if (!isLoading) {
    return (
      <div>
        <div className="container mb-4 mt-4 p-3">
          <div>
            <h1 className="text-center">-</h1>
            <div className="row screen">
              <button
                className="btn btn-primary"
                onClick={() => window.print()}
              >
                Print
              </button>
          </div>
          </div>
          <br/>
          <div className="row screen">
            <div className="col-md-12" style={{'margin-bottom':'30px'}}>
              <Form className="col-md-12" inline onSubmit={(e) => e.preventDefault()}>
                <FormGroup className="col-md-12" style={{padding:0}}>
                  <textarea
                    id="filter-input"
                    value={reportTitle}
                    placeholder="Report Title"
                    onChange={(e) => setReportTitle(e.target.value)}
                    style={{width:'100%', fontSize:'20px'}}
                    className="form-control"
                    rows="4" cols="50"
                  >
                  </textarea>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
        <ReportComponent title={title} reports={reports} />
      </div>
    );
  }

  return <div class="row">
    <div className="col-xs-6"></div>
    <div className="col-xs-6">
      <BounceLoader
        color={'#9da4cf'}
        loading={true}
        size={70}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  </div>;
};

export default ReportPage;